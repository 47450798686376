import { useEffect, useState } from "react";
import ResourceModel from "../../models/ResourceModel";

const resourceId = window.location.pathname.split("/")[2];
const token = window.location.pathname.split("/")[3];

export const ResourcePage:React.FC<{category: string, onCategoryChange: any}> = (props) => {
    const [resource, setResource] = useState<ResourceModel>();
    const [httpError, setHttpError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [accessGranted, setAccessGranted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchResource = async () => {
            const baseUrl: string = `${process.env.REACT_APP_API}/resources/${resourceId}`;
            const response = await fetch(baseUrl);

            if (!response.ok) {
                throw new Error("Something went wrong!");
            }

            const responseJson = await response.json();

            const loadedResource: ResourceModel = {
                id: responseJson.id,
                title: responseJson.title,
                path: responseJson.path,
                category: responseJson.category,
                displayHeight: responseJson.displayHeight,
                displayWidth: responseJson.displayWidth
            };

            if (loadedResource.category !== props.category) {
                props.onCategoryChange(loadedResource.category);
                setResource(loadedResource);
                setIsLoaded(true);
            }
            else {
                setResource(loadedResource);
                setIsLoaded(true);
            }
        }
        fetchResource().catch((error: any) => {
            setHttpError(true);
        });
    }, []);

    useEffect(() => {
        const validateCheckout = async () => {
            const baseUrl: string = `${process.env.REACT_APP_API}/wvAssets/${resourceId}/token/${token}`;
            const response = await fetch(baseUrl);

            if (!response.ok) {
                throw new Error("Something went wrong!");
            }

            const responseJson = await response.json();
            console.log(responseJson);

            if (responseJson.result === "OK") {
                setAccessGranted(true);
            }
            else if (responseJson.result === "RENTAL_EXPIRED") {
                setErrorMessage("Your rental period of 7 days has expired.");
            }
            else if (responseJson.result === "MAX_LAUNCHES") {
                setErrorMessage("You have achieved the maximum number of launches allowed for this rental.");
            }
            else if (responseJson.result === "INVALID_PATH") {
                setErrorMessage("Wrong rental link.");
            }
            else {
                setErrorMessage("Wrong rental link.");
            }
        }
        validateCheckout().catch((error: any) => {
            //setHttpError(true);
        });
    }, []);

    return (
        <div className="mt-5 mb-5">
            {httpError &&
                <>Something went wrong.</>
            }
            {isLoaded && accessGranted &&
                <div className="container-fluid">
                    <div className="m-3 d-flex justify-content-center align-items-center">
                        <h2 className="text-title-purple">{resource?.title}</h2>
                    </div>
                    <div className="m-3 d-flex justify-content-center align-items-center">
                        <iframe
                            src={`${process.env.REACT_APP_BASE}/wvAssets/wvRentals${resource?.path}/index.html?${Date.now()}:${resource?.path?.substring(1)}:${token}`}
                            title={resource?.title}
                            height={resource?.displayHeight}
                            width={resource?.displayWidth}>
                        </iframe>
                    </div>
                </div>
            }
            {isLoaded && !accessGranted &&
                <div>
                    <h1 className='display-5 fw-bold text-center mt-5'>{errorMessage}</h1>
                </div>
            }
        </div>
    )
}